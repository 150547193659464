.radio-span{
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    min-width: 170px;
    word-break: break-word;
    text-align: center;
    width: 100%;
    /* min-width: 170px!important; */
    max-width: 170px!important;
}
.radioheadings{
    display: flex;
    column-gap: 20px;
    width: 100%;
    margin-top: 20px;
}
.question-option{
    grid-column-gap: 20px;
    display: flex !important;
    grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
    margin-block: 30px;
    width: 100%;
}
.radiobuttongrouped{
    display: flex;
    align-items: center;
}
span{
    font-size: 20px;
    color: #5b5c5d;
}
input[type=radio]{
    min-width: 20px !important;
    height: 20px;
    
}
.radiobuttons{
    line-height: 22px;
}
.radiobuttons1{
    overflow-x: scroll;
}
.innerdivradio{
    display: flex;
    margin-top: 9px;
}
/* .form-radio{
    margin-left: 12px;
} */
@media (max-width: 900px){
    .radio-span{
        margin-bottom: 10px;
    }
    .form-radio{
        margin-block: 2px;
    }
}