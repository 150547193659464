@import "https://js.arcgis.com/4.25/@arcgis/core/assets/esri/themes/light/main.css";
@import "https://cdn.jsdelivr.net/npm/@arcgis/core@4.25.0-next.20220921/assets/esri/themes/light/main.css";

.card{
    position: relative;
    color: #fff;
    background-color: transparent;
    float: left;
    left: 0;
    padding-left: 10%;
    padding-right: 10%;
    right: 0;
    width: 100%;
    z-index: 99;
}

.card.soulful{
    position: relative;
    color: #fff;
    background-color: #0EA387;
    float: left;
    left: 0;
    padding-left: 10%;
    padding-right: 10%;
    right: 0;
    width: 100%;
    z-index: 99;
}
.formbg{
    padding: 40px ;
    align-items: center;
    background-color: #ecf0f4;
    width: 100%;
}
.formbg .input-group-child{
    display: flex;
    grid-column-gap: 5%;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
}

.formbg.weact{
    padding-inline: 40px !important;
    padding-bottom: 40px !important;
    padding-top: 0px !important;
    align-items: center;
    background-color: white;
    width: 100%;
}
.formbg.para.weact .input-group-child{
    display: flex;
    grid-column-gap: 5%;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
}

.formbg.soulful {
    padding-inline: 10px !important;
    padding-bottom: 40px !important;
    padding-top: 0px !important;
    align-items: center;
    background-color: #0EA387;
    width: 100%;
    font-weight: 600;
}

.single-group-child {
    background: #fff;
    color: #000!important;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
}

.strongclass{
    font-weight: 600 !important;
    padding-left: 2px;
    font-size: 18px;
}

.strongclass.soulful-application{
    font-weight: 700 !important;
    padding-left: 0 !important;
    font-size: 18px;
    line-height: 0.2;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.strongclass.soulful-application.special-component{
    font-weight: 400 !important;
    padding-left: 0 !important;
    font-size: 18px;
    line-height: 0.2;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

span.soulful-application.option-label {
    font-weight: 300 !important;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

.question-element.soulful-application.special-component{
    font-weight: 400 !important;
    padding-left: 0 !important;
    font-size: 18px;
    line-height: 0.2;
    color: black;
    font-family: 'Montserrat', sans-serif;
}

/* .special-component */

.mandatory{
    color: red;
    font-size: 23px;
    padding-left: 4px;
    /* margin-inline-start: 4px; */
}
.centertext{
    color: #979699;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

.centertext.weact{
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

.centertext.soulful{
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 0;
}

.form{
    margin-top: 10px;
}

.form.soulful-application{
    margin-top: 0px;
}

.para{
    color: #5b5c5d;
    font-size: 20px;
    margin-bottom: 25px;
    margin-top: 12px;
    text-align: justify;
}

.para.weact{
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 25px;
    margin-top: 12px;
    text-align: justify;
}

.para.soulful{
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: 12px;
    text-align: justify;
    line-height: 1.2;
}
.formheading{
    color: #08416a!important;
    font-family: Montserrat,sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 40px;
}
.error-in-question {
    border: 1px solid #ff303078;
    box-shadow: 1px 1px 8px 0px #ff000091;
    position: relative;
}

.error-message-container {
    width: 100%;
    /* background: #e53935; */
    padding: inherit;
    color: red;
    font-weight: 600;
}

input[type=text]:focus {
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);
}

input[type=numeric] {
    width: 100%;
    height: 39px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #d1d0d0;
}

input[type=numeric]:focus {
    /* box-shadow: 0 0 5px rgba(81, 203, 238, 1); */
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);
}

input[type=number] {
    width: 100%;
    height: 39px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #d1d0d0;
}

input[type=number]:focus {
    /* box-shadow: 0 0 5px rgba(81, 203, 238, 1); */
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid rgba(81, 203, 238, 1);
}

@media (max-width: 900px) {
    .card{
        padding-left: 0;
        padding-right: 0;
    }

    .card.soulful {
        padding-left: 0;
        padding-right: 0;
    }

    .formbg{
        padding-inline:10px;
    }
    
    .formbg.weact{
        padding-inline:10px !important;
    }
    .para{
        font-size: 16px;
        text-align: left;
    }
    .centertext{
        font-size: 24px;
    }

    .formbg.weact .input-group-child{
        display: flex;
        flex-direction: column;
    }
}